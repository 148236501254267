
import { defineComponent, ref } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as Yup from 'yup'
import { VueEditor } from 'vue3-editor'
import ApiService from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { AxiosRequestConfig } from 'axios'
import { useI18n } from 'vue-i18n'
import { event } from 'vue-gtag'

interface Answer {
    id?: number
    qId?: number
    text: string
    isCorrect: boolean
}

interface Question {
    id?: number
    text: string
    answers: Answer[]
}

interface Exercice {
    trimester: string
    subject: string
    title: string
    module: string
    desc: string
    deadline: string
    marked: boolean
    questions: Question[]
}

interface Subject {
    _id: string
    name: string
    status: string
}

interface Module {
    _id: string
    name: string
    subjects: Subject[]
    status: string
}

interface Student {
    _id: string
    firstName: string
    lastName: string
    schoolarYearsHistory: string[]
}

export default defineComponent({
    name: 'student-add-exercise',
    components: {
        ErrorMessage,
        Field,
        Form,
        VueEditor,
    },
    props: {
        widgetClasses: String,
        id: String,
    },
    setup() {
        const { t } = useI18n()

        const submitButtonRef = ref<null | HTMLButtonElement>(null)
        // eslint-disable-next-line
        const upload = ref<any>(null)
        const subjects = ref<Subject[]>([])
        const modules = ref<Module[]>([])

        const classID = ref<string>('')
        const uploadPercentage = ref(0)

        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const studentID = route.params.id

        const jwtTrimester = JwtService.getTrimester()
        let trimester = '1'
        if (jwtTrimester && jwtTrimester != '0') trimester = jwtTrimester

        const exercice = ref<Exercice>({
            trimester,
            subject: '',
            title: '',
            module: '',
            desc: '',
            deadline: '',
            marked: true,
            questions: [
                { text: '', answers: [{ text: '', isCorrect: false }] },
            ],
        })

        const spec = ref<string>('0')
        const exerciseType = ref('exercise')

        const validationSchema = Yup.object().shape({
            title: Yup.string().required(t('course.requiredTitle')),
            subject: Yup.string().required(t('course.requiredSubject')),
        })

        // eslint-disable-next-line
        const uploadFiles = ref<any>([])

        const updateUploadFiles = (file, files) => {
            uploadFiles.value = files
        }

        // submit the homework
        const submit = () => {
            if (exerciseType.value == 'quiz') return submitQuiz()

            const data = new FormData()

            if (!exercice.value.deadline) exercice.value.deadline = ''

            data.append('students', student.value._id)

            for (const name in exercice.value)
                if (name != 'students' && name != 'questions')
                    data.append(name, exercice.value[name])

            uploadFiles.value.forEach((file) => {
                data.append('resources', file.raw, file.raw.name)
            })

            data.append('classrooms', classID.value)
            data.append(
                'schoolarYear',
                localStorage.getItem('activeSchoolarYear') as string
            )

            if (!submitButtonRef.value) {
                return
            }

            //Disable button
            submitButtonRef.value.disabled = true
            // Activate indicator
            submitButtonRef.value.setAttribute('data-kt-indicator', 'on')

            event('Add specific exercise', {
                event_category: 'Exercises',
                event_label: 'Student profile',
                value: 1,
            })

            ApiService.put(
                'lms/homework',
                data as AxiosRequestConfig,
                {
                    onUploadProgress: function (progressEvent) {
                        uploadPercentage.value = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                    },
                } as any
            )
                .then(({ data }) => {
                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false

                        submitButtonRef.value?.removeAttribute(
                            'data-kt-indicator'
                        )
                    }

                    Swal.fire({
                        text: t('course.addedExercise'),
                        icon: 'success',
                        buttonsStyling: false,
                        confirmButtonText: 'ok',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                    }).then(() => {
                        router.push(`/exercise/${data._id}`)
                    })
                })
                .catch((err) => {
                    if (err.response.status == 413)
                        Swal.fire({
                            text: t('course.filesTooLarge'),
                            icon: 'warning',
                            buttonsStyling: false,
                            confirmButtonText: t('course.okay'),
                            customClass: {
                                confirmButton: 'btn fw-bold btn-light-danger',
                            },
                        })
                    else
                        Swal.fire({
                            text: t('course.errorText'),
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: t('course.tryAgain'),
                            customClass: {
                                confirmButton: 'btn fw-bold btn-light-danger',
                            },
                        })
                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false

                        submitButtonRef.value?.removeAttribute(
                            'data-kt-indicator'
                        )
                    }
                })
        }

        const submitQuiz = () => {
            let test = false

            exercice.value.questions.map((question: Question) => {
                if (question.text == '') {
                    test = true
                    return
                }
                question.answers.map((answer: Answer) => {
                    if (answer.text == '') {
                        test = true
                        return
                    }
                })
            })

            if (test)
                return Swal.fire({
                    text: t('course.pleaseFillOrDeleteAnswers'),
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: t('course.tryAgain'),
                    customClass: {
                        confirmButton: 'btn fw-bold btn-light-danger',
                    },
                })

            // eslint-disable-next-line
            const data: any = {}

            if (!exercice.value.deadline) exercice.value.deadline = ''

            data.students = [student.value._id]
            data.classrooms = [classID.value]

            let questionId = 0

            exercice.value.questions = exercice.value.questions.map(
                (question) => {
                    question.id = questionId++
                    let answerId = 0
                    question.answers = question.answers.map((answer) => {
                        answer.qId = question.id
                        answer.id = answerId++
                        return answer
                    })
                    return question
                }
            )

            data.questions = exercice.value.questions

            for (const name in exercice.value)
                if (name != 'students' && name != 'desc' && name != 'questions')
                    data[name] = exercice.value[name]

            data.schoolarYear = localStorage.getItem(
                'activeSchoolarYear'
            ) as string

            if (!submitButtonRef.value) {
                return
            }

            //Disable button
            submitButtonRef.value.disabled = true
            // Activate indicator
            submitButtonRef.value.setAttribute('data-kt-indicator', 'on')

            ApiService.put('/lms/quiz', data as AxiosRequestConfig)
                .then(({ data }) => {
                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false

                        submitButtonRef.value?.removeAttribute(
                            'data-kt-indicator'
                        )
                    }

                    Swal.fire({
                        text: t('course.addedExercise'),
                        icon: 'success',
                        buttonsStyling: false,
                        confirmButtonText: 'ok',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                    }).then(() => {
                        router.push(`/quiz/${data._id}/`)
                    })
                })
                .catch(() => {
                    Swal.fire({
                        text: t('course.errorText'),
                        icon: 'error',
                        buttonsStyling: false,
                        confirmButtonText: t('course.tryAgain'),
                        customClass: {
                            confirmButton: 'btn fw-bold btn-light-danger',
                        },
                    })
                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false

                        submitButtonRef.value?.removeAttribute(
                            'data-kt-indicator'
                        )
                    }
                })
        }

        const student = ref<Student>({
            _id: '',
            firstName: '',
            lastName: '',
            schoolarYearsHistory: [],
        })

        // get subject list
        const getSubjects = (classid) => {
            ApiService.post('/teacher/classRommDetails', {
                query: {
                    teacher: store.getters.currentUser._id,
                },
            })
                .then(({ data }) => {
                    subjects.value = []
                    modules.value = []
                    const classroom = data.find(
                        (el) => el.classRoom._id == classid
                    )
                    if (classroom) {
                        subjects.value = classroom.subjects

                        classroom.modulesSpecific.map((m) => {
                            if (!modules.value.find((t) => t._id == m._id))
                                modules.value.push(m)
                        })

                        classroom.modules.map((m) => {
                            if (!modules.value.find((t) => t._id == m._id))
                                modules.value.push(m)
                        })
                    }

                    //remove duplicated
                    subjects.value = subjects.value.filter(
                        (subject, index) =>
                            subjects.value.findIndex(
                                (s) => s._id == subject._id
                            ) === index &&
                            !modules.value.find((m) =>
                                m.subjects.find((ss) => ss._id == subject._id)
                            ) &&
                            subject.status !== 'inactive'
                    )
                })
                .catch((e) => console.log(e))
        }

        // find the student
        const sy = window.localStorage.getItem('activeSchoolarYear')
        ApiService.post('students/filter', {
            query: { _id: studentID },
            aggregation: [
                {
                    $set: {
                        classRoom: {
                            $convert: {
                                input: '$schoolarYearsHistory.' + sy,
                                to: 'objectId',
                                onError: null,
                                onNull: null,
                            },
                        },
                    },
                },
                {
                    $project: {
                        _id: 1,
                        firstName: 1,
                        lastName: 1,
                        schoolarYearsHistory: 1,
                    },
                },
            ],
        })
            .then(({ data }) => {
                if (data.length > 0) {
                    student.value = data[0]
                    // student's classroom
                    classID.value =
                        student.value.schoolarYearsHistory[sy as string]
                    getSubjects(classID.value)
                } else console.error(data)
            })
            .catch((e) => console.log(e))

        const handleExceed = () => {
            Swal.fire({
                text: t('course.maxFilesUploadExceed'),
                icon: 'info',
                buttonsStyling: false,
                confirmButtonText: t('course.tryAgain'),
                customClass: {
                    confirmButton: 'btn fw-bold btn-light-info',
                },
            })
        }

        const customColorMethod = (percentage: number) => {
            if (percentage < 30) {
                return '#909399'
            }
            if (percentage < 70) {
                return '#e6a23c'
            }
            return '#67c23a'
        }

        return {
            spec,
            student,
            t,
            studentID,
            subjects,
            modules,
            upload,
            exercice,
            validationSchema,
            submit,
            submitButtonRef,
            updateUploadFiles,
            exerciseType,
            handleExceed,
            uploadPercentage,
            customColorMethod,
        }
    },
})
